<template>
    <div class="modal-mask" >
        <div class="modal-wrapper" @click="close">
                    <!-- search-box -->
                <search-box @close="close"/>

                    <!--search result-->
                <search-result :results="results"  />
            <footer class="mt-3">
                <kbd>esc</kbd>
                &copy; Nasarawa State University, Transcript {{ year }}
            </footer>
        </div>
    </div>
</template>


<script >
import SearchBox from "./SearchBox.vue"; 
import SearchResult from "./Results.vue"; 
export default {
    components: { SearchBox, SearchResult },
    emits: ['close'],
    props: ["open"], 
    data: () => ({
        results: [
            {
                name: "Dummy Title",
                occupation: "subtitle for nothing"
            }
        ], 
    }), 

    methods: {
        close() {
            this.$emit('close'); 
        }
    }, 

    computed: {
        year: function(){
            return new Date().getFullYear(); 
        }
    },
}
</script>


<style lang="scss" scoped>
$input-color: #333;
$input-background: #f6f9fc;
$icon-color: darken($input-background, 30%);
$active-color: #14C38E;

.modal-mask {
    position: fixed;
    z-index: 9999;
    top: 0; 
    left: 0; 
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .modal-wrapper {
        bottom: 10rem;
        width: 40em;
        padding: 20px 30px;
        margin: 8rem auto 0rem auto;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3 ease;

        footer {
            color: gray;
            font-size: 0.8em;
            text-align: right;
        }
    }
}
</style>